// @flow

import React from "react";
import {
  Grid,
  Button,
  Stamp,
  Card,
  Container,
  Form,
  Tabs,
  Tab,
  Table,
  Text
} from "tabler-react";
import { Uploader, SelectPicker, DatePicker } from 'rsuite';

import SiteWrapper from "../../../SiteWrapper.react";

const contratos = [
    {"label" : "0001 - Elber Machado", "value" : "1"},
    {"label" : "0002 - Alexandre Alberto", "value" : "2"},
    {"label" : "0003 - Bela Alves", "value" : "3"},
    {"label" : "0004 - Carlos Villagran", "value" : "4"},
    {"label" : "0005 - Roberto Gomez", "value" : "5"},
    {"label" : "0006 - Florinda Meza", "value" : "6"},
    {"label" : "0007 - Maria Antonieta", "value" : "7"},
];

function EditIncome() {
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card statusColor="info">
                    <Card.Header>
                        <Card.Title>Receita</Card.Title>
                        <Card.Options>
                        <Stamp size="sm" className="mr-1" color="info">Parcial</Stamp>
                        </Card.Options>
                    </Card.Header>
                    <Card.Body>
                        <Form> 
                            <Tabs initialTab="Despesa">
                                <Tab title="Despesa">
                                    <Grid.Row className="align-items-center">
                                            <Grid.Col sm={6}>
                                                <Form.Group label="Contrato">
                                                    <SelectPicker placeholder="Selecione o contrato" data={contratos} block valueKey="1"/>
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col sm={6}>
                                                <Form.Group label="Valor">
                                                    <Form.Input type="text"  placeholder="Valor" value="5.000,00"/>
                                                </Form.Group>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col md={6}>
                                                <Form.Group label="Emissão">
                                                    <DatePicker block format="DD/MM/YYYY" value="2020-04-25"/>
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Vencimento">
                                                    <DatePicker block format="DD/MM/YYYY" value="2020-05-25" />
                                                </Form.Group>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.Group> 
                                    <Form.Group label="Notas">
                                        <Form.Textarea rows={5} placeholder="Notas" value="Integer volutpat sapien et massa volutpat faucibus. Praesent semper quam ligula, a euismod felis iaculis gravida. Curabitur aliquam varius augue, at laoreet eros pretium nec. Donec et vestibulum erat. Nulla nibh leo, ullamcorper quis eros sed, maximus auctor sem. Cras."/>
                                    </Form.Group>
                                    <Grid.Row className="align-items-center">
                                    <Grid.Col sm={6}>
                                        <Form.Group>
                                            <Form.Input type="text"  placeholder="Documento" label="Documento" value="FAT0001/2"/>
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col sm={6}>
                                        <Form.Group label="Arquivo">
                                            <Uploader action="//aldesenvolvimento.com.br/posts/" />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                                </Tab>
                                <Tab title="Pagamentos">
                                    <Grid.Col width={12}>
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>Parcelas</Card.Title>
                                                    <Card.Options>
                                                        <Button color="success" icon="plus" size="sm" className="text-white" type="Button" RootComponent="a" href="/pagamento">Novo Pagamento</Button>
                                                    </Card.Options>
                                                </Card.Header>
                                                    <Table
                                                        responsive
                                                        className="card-table table-vcenter text-nowrap"
                                                        headerItems={[
                                                        { content: "#", className: "w-1" },
                                                        { content: "Valor" },
                                                        { content: "Data Pagamento" },
                                                        { content: "Usuário" },
                                                        { content: "Ações" },
                                                        ]}
                                                        bodyItems={[
                                                        {
                                                            key: "1",
                                                            item: [
                                                            {
                                                                content: (
                                                                <Text RootComponent="span" muted>
                                                                    0001
                                                                </Text>
                                                                ),
                                                            },
                                                            {content: "R$ 3.500,00"},
                                                            {content: "10/05/2020"},
                                                            {content: "Joana Alves"},
                                                            {
                                                                alignContent: "left",
                                                                content: (
                                                                <React.Fragment>
                                                                    <Button icon="eye" size="sm" color="secondary" RootComponent="a" href="/pagamento/1">Ver</Button>
                                                                </React.Fragment>
                                                                ),
                                                            },
                                                            ],
                                                        },
                                                        ]}
                                                    />
                                        </Card>
                                </Grid.Col>                     
                                </Tab>
                            </Tabs>
                        </Form>                           
                    </Card.Body>
                    <Card.Footer>
                            <Button.List className="mt-4" align="right">
                                <Button color="secondary" RootComponent="a" href="/receitas">Cancelar</Button>
                                <Button color="primary">Salvar</Button>
                            </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default EditIncome;
