import React from 'react';
import { Modal, Button, Icon, IconButton } from 'rsuite';
import { Form, FormGroup, FormControl, ControlLabel, InputNumber, Message} from 'rsuite';

class OwModal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        show: false,
        parcelas: 1
        /*textButton: props.textButton,
        textTitle: props.textTitle,
        textBody: props.textBody,
        content: 'renegociar'*/
      };
      this.close = this.close.bind(this);
      this.open = this.open.bind(this);
    }
    close() {
      this.setState({ show: false });
    }
    open() {
      this.setState({ show: true });
      console.log(this.state.content);
    }

    setParcelas(value){
      this.setState({ parcelas: value });
    }
    render() {
      return (
        <div style={{display: 'content'}}>
          <IconButton color="green" icon={<Icon icon="undo" />} size="sm" onClick={this.open}> Renegociar</IconButton>
          <div className="modal-container">          
            <Modal show={this.state.show} onHide={this.close} >
              <Modal.Header>
                <Modal.Title>Renegociação</Modal.Title>
              </Modal.Header>
              <Modal.Body>     
                  <Message style={{ marginBottom: 15 + 'px'}}
                    description="Ao renegociar o contrato o sistema irá gerar novas receitas e atualizar as receitas existentes que estão em aberto." 
                  /> 
                  <Form fluid>
                      <FormGroup>
                          <ControlLabel>Valor em aberto</ControlLabel>
                          <FormControl name="valor" value="7.500,00" disabled/>
                      </FormGroup>
                      <FormGroup>
                          <ControlLabel>Parcelas</ControlLabel>
                          <InputNumber
                            min={1}
                            max={36}
                            value={this.parcelas}
                            onChange={value => {
                              this.setParcelas(value);
                            }}
                          />
                      </FormGroup>
                      <FormGroup>
                          <ControlLabel>Juros</ControlLabel>
                          <InputNumber postfix="%" min={0} max={100} />
                      </FormGroup>
                  </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.close} appearance="subtle">
                  Cancelar
                </Button>
                <Button onClick={this.close} appearance="primary">
                  Aplicar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      );
    }
  }
  
  export default OwModal;