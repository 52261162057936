import React from 'react';
import { Table } from 'rsuite';
import { Button, IconButton, ButtonGroup, ButtonToolbar } from 'rsuite';
import { Icon, Tag } from 'rsuite';
import { Dropdown } from 'rsuite';

import OwActionButtons from './OwActionButtons';

const { Column, HeaderCell, Cell } = Table;

const fakeData = [
    {"receita" : 5,"contrato":"0001","matricula" : "96325874", "cliente" : "Elber Machado", "valor" : "R$ 5.500,00", "valor-baixa" : "R$ 4.800,00", "diferenca" : "R$ 700,00"},
    {"receita" : 4,"contrato":"0003","matricula" : "78452132", "cliente" : "Bela Alves", "valor" : "R$ 12.000,00", "valor-baixa" : "R$ 10.800,00", "diferenca" : "R$ 1.200,00"},

];

class OwTable extends React.Component {
    constructor(props) {
      super(props);
      //const data = fakeData.filter((v, i) => i < 100);
      //const data = fakeData;
      this.state = {
        data: props.data || fakeData,
        addColumn: false,
        displayLength: 10,
        loading: false,
        page: 1
      };
      this.handleSortColumn = this.handleSortColumn.bind(this);
      this.handleChangePage = this.handleChangePage.bind(this);
      this.handleChangeLength = this.handleChangeLength.bind(this);
    }
  
    getData() {
      const { sortColumn, sortType, displayLength, page } = this.state;

      var repo = fakeData;
  
      if (sortColumn && sortType) {
        repo = fakeData.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt();
          }
          if (typeof y === 'string') {
            y = y.charCodeAt();
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }

      return repo.filter((v, i) => {
          const start = displayLength * (page - 1);
          const end = start + displayLength;
          return i >= start && i < end;
      });

    }
  
    handleSortColumn(sortColumn, sortType) {
      this.setState({
        loading: true
      });
  
      setTimeout(() => {
        this.setState({
          sortColumn,
          sortType,
          loading: false
        });
      }, 500);
    }

    handleChangePage(dataKey) {
      this.setState({
        page: dataKey
      });
    }

    handleChangeLength(dataKey) {
      this.setState({
        page: 1,
        displayLength: dataKey
      });
    }
  
    render() {
      const data = this.getData();
      const { loading, displayLength, page, sortColumn, sortType  } = this.state;

      return (
        <div>
          <Table
            autoHeight
            height={420}
            data={this.getData()}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={this.handleSortColumn}
            loading={loading}
          >

            <Column width={100} fixed sortable resizable>
              <HeaderCell>Receita</HeaderCell>
              <Cell dataKey="contrato" />
            </Column>

            <Column width={100} fixed sortable resizable>
              <HeaderCell>Contrato</HeaderCell>
              <Cell dataKey="contrato" />
            </Column>
  
            <Column width={100} sortable resizable>
              <HeaderCell>Matrícula</HeaderCell>
              <Cell dataKey="matricula" />
            </Column>
  
            <Column width={150} sortable resizable>
              <HeaderCell>Cliente</HeaderCell>
              <Cell dataKey="cliente" />
            </Column>

            <Column width={150} resizable sortable> 
              <HeaderCell>Valor</HeaderCell>
              <Cell dataKey="valor" />
            </Column>

            <Column width={150} resizable sortable> 
              <HeaderCell>Valor Baixa</HeaderCell>
              <Cell dataKey="valor-baixa" />
            </Column>

            <Column width={150} resizable sortable> 
              <HeaderCell>Diferença</HeaderCell>
              <Cell dataKey="diferenca" />
            </Column>


            <Column align="center" fixed>
              <HeaderCell>Ações</HeaderCell>
              <Cell>
                <Button appearance="default" style={{marginTop: -9 + 'px'}} href="/receita/1">Resolver</Button>
              </Cell>
            </Column>
          </Table>
          <Table.Pagination
            lengthMenu={[
              {
                value: 10,
                label: 10
              },
              {
                value: 25,
                label: 25
              },
              {
                value: 50,
                label: 50
              },
              {
                value: 100,
                label: 100
              }
            ]}
            activePage={page}
            displayLength={displayLength}
            total={fakeData.length}
            onChangePage={this.handleChangePage}
            onChangeLength={this.handleChangeLength}
          />
        </div>
      );
    }
}

export  default OwTable;