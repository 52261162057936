import React from "react";
import { DateRangePicker } from 'rsuite';
import { addDays, startOfDay, endOfDay, subDays } from 'date-fns';

const Ranges = [
    {
      label: 'Hoje',
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: 'Ontem',
      value: [
        startOfDay(addDays(new Date(), -1)),
        endOfDay(addDays(new Date(), -1))
      ]
    },
    {
      label: 'Últimos 7 dias',
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
    }
];

function DateRangePtBr(props){
    return (
        <DateRangePicker 
            appearance="default"
            placeholder={props.placeholder}
            block
            ranges={Ranges}
            format="DD/MM/YYYY"
            locale={{
                sunday: 'Do',
                monday: 'Se',
                tuesday: 'Te',
                wednesday: 'Qu',
                thursday: 'Qu',
                friday: 'Se',
                saturday: 'Sa',
                ok: 'Aplicar',
            }}
        />
    )
}

export default DateRangePtBr;