// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form,
} from "tabler-react";

import { Uploader, SelectPicker, DatePicker } from 'rsuite';

import SiteWrapper from "../../../SiteWrapper.react";

const fontes = [
    {"label" : "FASP", "value" : "1"},
];

function CreatePaymentFromFile() {
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Baixa Via Arquivo</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>                    
                            <Grid.Row className="align-items-center">
                                <Grid.Col sm={6}>
                                    <Form.Group label="Fonte">
                                        <SelectPicker placeholder="Selecione fonte do arquivo" data={fontes} block />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={6}>
                                    <Form.Group label="Data">
                                        <DatePicker block disabled format="DD/MM/YYYY" value={new Date()}/>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row> 
                            <Grid.Row className="align-items-center">
                                <Grid.Col sm={12}>
                                    <Form.Group label="Arquivo">
                                        <Uploader block action="//aldesenvolvimento.com.br/posts/" />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                        </Form>                           
                    </Card.Body>
                    <Card.Footer>
                            <Button.List className="mt-4" align="right">
                                <Button color="secondary" RootComponent="a" href="/baixas-arquivo">Cancelar</Button>
                                <Button color="primary">Processar</Button>
                            </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default CreatePaymentFromFile;