// @flow

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';

import { IntlProvider } from 'rsuite';
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR';

import "./index.css";
import "./c3jscustom.css";

import App from "./App.react";

//const baseUrl = document.getElementsByTagName("base")[0].getAttribute('href');
const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(<IntlProvider locale={ptBR}><App /> </IntlProvider>, rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}
