// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form,
} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";

import { Uploader, DatePicker } from 'rsuite';

function Payment() {
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Pagamento</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>                    
                            <Form.Group>
                                <Grid.Row className="align-items-center">
                                  <Grid.Col md={6}>
                                      <Form.Group label="Data">
                                        <DatePicker block format="DD/MM/YYYY" />
                                      </Form.Group>
                                  </Grid.Col>
                                    <Grid.Col sm={6}>
                                        <Form.Input type="text" label="Valor" placeholder="Valor pago"/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Form.Group>
                            <Grid.Row className="align-items-center">
                                <Grid.Col sm={12}>
                                    <Form.Group>
                                        <Form.Textarea rows={5} placeholder="Descrição" label="Descrição"/>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col sm={12}>
                                    <Form.Group label="Comprovante">
                                        <Uploader action="//aldesenvolvimento.com.br/posts/" /> 
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                        </Form>                           
                    </Card.Body>
                    <Card.Footer>
                            <Button.List className="mt-4" align="right">
                                <Button color="secondary" RootComponent="a" href="/receita/1">Cancelar</Button>
                                <Button color="primary">Salvar</Button>
                            </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default Payment;
