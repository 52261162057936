// @flow

import React from "react";

import {
  Grid,
  Button,
  Card,
  Container,
  Form,
  Tabs,
  Tab,
  Table,
  Text,
} from "tabler-react";

import { ButtonToolbar, ButtonGroup, Icon, IconButton } from 'rsuite';

import SiteWrapper from "../../SiteWrapper.react";
import OwModal from "../../components/OwModal";
import OwModalQuitar from "../../components/OwModalQuitar";

import { Uploader, SelectPicker, DatePicker } from 'rsuite';

const clientes = [
    {"label" : "Elber Machado", "value" : "1"},
    {"label" : "Alexandre Alberto", "value" : "2"},
    {"label" : "Bela Alves", "value" : "3"},
    {"label" : "Carlos Villagran", "value" : "4"},
    {"label" : "Roberto Gomez", "value" : "5"},
    {"label" : "Florinda Meza", "value" : "6"},
    {"label" : "Maria Antonieta", "value" : "7"},
];


function EditContract() {

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Contrato</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>      
                        <Tabs initialTab="Geral">
                            <Tab title="Geral">              
                                <Form.Group>
                                    <Grid.Row className="align-items-center">
                                            <Grid.Col md={12}>
                                            <Form.Group label="Cliente">
                                                <SelectPicker placeholder="Selecione o cliente" data={clientes} block valueKey={1}/>
                                            </Form.Group>
                                            </Grid.Col>
                                    </Grid.Row> 
                                </Form.Group>
                                <Form.Group>
                                    <Grid.Row className="align-items-center">
                                        <Grid.Col md={6}>
                                            <Form.Input type="text"  placeholder="0,00" label="Valor do contrato" value="10.000,00"/>
                                        </Grid.Col>
                                        <Grid.Col md={6}>
                                        <Form.Group label="Nº Parcelas">
                                            <Form.Ratio
                                                defaultValue={4}
                                                max={48}
                                                min={1}
                                                step={1}
                                            />
                                        </Form.Group>              
                                    </Grid.Col>
                                    </Grid.Row>
                                </Form.Group>      
                                <Form.Group>
                                    <Grid.Row className="align-items-center">
                                            <Grid.Col md={6}>
                                                <Form.Group label="Início">
                                                    <DatePicker block format="DD/MM/YYYY" value="2017-03-15"/>
                                                </Form.Group>                                                
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Início">
                                                    <DatePicker block format="DD/MM/YYYY" value="2021-03-15"/>
                                                </Form.Group>
                                            </Grid.Col>
                                    </Grid.Row>
                                </Form.Group>  
                                <Grid.Row className="align-items-center">
                                    <Grid.Col md={4}>
                                        <Form.Group label="Anexar contrato">
                                            <Uploader action="//aldesenvolvimento.com.br/posts/" />
                                        </Form.Group>              
                                    </Grid.Col>
                                    <Grid.Col md={4}>
                                        <Form.Group label="Anexar Pós-Venda">
                                            <Uploader action="//aldesenvolvimento.com.br/posts/" />
                                        </Form.Group>              
                                    </Grid.Col>
                                    <Grid.Col md={4}>
                                        <Form.Group label="Anexar contracheque">
                                            <Uploader action="//aldesenvolvimento.com.br/posts/" />
                                        </Form.Group>              
                                    </Grid.Col>
                                </Grid.Row>
                                <Form.Group label="Observações">
                                    <Form.Textarea rows={5} placeholder="Observações" value="Integer volutpat sapien et massa volutpat faucibus. Praesent semper quam ligula, a euismod felis iaculis gravida. Curabitur aliquam varius augue, at laoreet eros pretium nec. Donec et vestibulum erat. Nulla nibh leo, ullamcorper quis eros sed, maximus auctor sem. Cras."/>
                                </Form.Group>
                            </Tab>
                            <Tab title="Financeiro">
                            <Grid.Col width={12}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Parcelas</Card.Title>
                                        <Card.Options>                                                                     
                                            <ButtonToolbar style={{ display: 'contents' }} justified>
                                                <OwModal />
                                                <OwModalQuitar />
                                            </ButtonToolbar>                                            
                                        </Card.Options>
                                    </Card.Header>
                                    <Table
                                        responsive
                                        className="card-table table-vcenter text-nowrap"
                                        headerItems={[
                                        { content: "#", className: "w-1" },
                                        { content: "No.", className: "w-1" },
                                        { content: "Valor" },
                                        { content: "Vencimento" },
                                        { content: "Data Pagamento" },
                                        { content: "Status" },
                                        { content: "Ações" },
                                        ]}
                                        bodyItems={[
                                        {
                                            key: "1",
                                            item: [
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    0001
                                                </Text>
                                                ),
                                            },
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    1
                                                </Text>
                                                ),
                                            },
                                            {content: "R$ 2.500,00"},
                                            {content: "15/06/2020"},
                                            {content: "15/06/2020"},
                                            {
                                                content: (
                                                <React.Fragment>
                                                    <span className="status-icon bg-success" /> Pago
                                                </React.Fragment>
                                                ),
                                            },
                                            {
                                                alignContent: "left",
                                                content: (
                                                <React.Fragment>
                                                    <Button icon="check" size="sm" color="secondary" disabled RootComponent="a" href="/pagamento">Pagar</Button>
                                                </React.Fragment>
                                                ),
                                            },
                                            ],
                                        },
                                        {
                                            key: "2",
                                            item: [
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    0002
                                                </Text>
                                                ),
                                            },
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    2
                                                </Text>
                                                ),
                                            },
                                            {content: "R$ 2.500,00"},
                                            {content: "15/07/2020"},
                                            {content: ""},
                                            {
                                                content: (
                                                <React.Fragment>
                                                    <span className="status-icon bg-danger" /> Pendente
                                                </React.Fragment>
                                                ),
                                            },
                                            {
                                                alignContent: "left",
                                                content: (
                                                <React.Fragment>
                                                    <Button icon="check" size="sm" color="secondary" RootComponent="a" href="/pagamento">Pagar</Button>
                                                </React.Fragment>
                                                ),
                                            },
                                            ],
                                        },
                                        {
                                            key: "3",
                                            item: [
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    0003
                                                </Text>
                                                ),
                                            },
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    3
                                                </Text>
                                                ),
                                            },
                                            {content: "R$ 2.500,00"},
                                            {content: "15/08/2020"},
                                            {content: ""},
                                            {
                                                content: (
                                                <React.Fragment>
                                                    <span className="status-icon bg-warning" /> Pendente
                                                </React.Fragment>
                                                ),
                                            },
                                            {
                                                alignContent: "left",
                                                content: (
                                                <React.Fragment>
                                                    <Button icon="check" size="sm" color="secondary" RootComponent="a" href="/pagamento">Pagar</Button>
                                                </React.Fragment>
                                                ),
                                            },
                                            ],
                                        },
                                        {
                                            key: "4",
                                            item: [
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    0004
                                                </Text>
                                                ),
                                            },
                                            {
                                                content: (
                                                <Text RootComponent="span" muted>
                                                    4
                                                </Text>
                                                ),
                                            },
                                            {content: "R$ 2.500,00"},
                                            {content: "15/09/2020"},
                                            {content: ""},
                                            {
                                                content: (
                                                <React.Fragment>
                                                    <span className="status-icon bg-warning" /> Pendente
                                                </React.Fragment>
                                                ),
                                            },
                                            {
                                                alignContent: "left",
                                                content: (
                                                <React.Fragment>
                                                    <Button icon="check" size="sm" color="secondary" RootComponent="a" href="/pagamento">Pagar</Button>
                                                </React.Fragment>
                                                ),
                                            },
                                            ],
                                        },
                                        ]}
                                    />
                                </Card>
                            </Grid.Col>
                            </Tab>
                        </Tabs>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <Button.List className="mt-4" align="right">
                            <Button color="secondary" RootComponent="a" href="/contratos">Cancelar</Button>
                            <Button color="primary">Salvar</Button>
                        </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default EditContract;