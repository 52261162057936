// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Table,
  Dropdown,
  Form 
} from "tabler-react";

import SiteWrapper from "../../SiteWrapper.react";

import OwTable from "../../components/OwTableContracts";
import DateRangePicker from '../../components/DateRangePicker';



function Contracts() {
  return (
        <SiteWrapper>
        <div className="my-3 my-md-5">
        <Container>
            <Grid.Row>
                <Grid.Col md={12}>
                    <Card>
                    <Card.Header>
                        <Card.Title>Listagem de Contratos</Card.Title>
                        <Card.Options>
                            <Button color="success" icon="plus" size="sm" className="text-white" RootComponent="a" href="/contrato">Adicionar</Button>
                        </Card.Options>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col md={6}>
                                <Form.Group >
                                    <Form.Input
                                        className="mb-3"
                                        icon="search"
                                        placeholder="Buscar contrato por número, cliente..."
                                        position="append"
                                    />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={3}>
                                <Form.Group>
                                    <DateRangePicker placeholder="Início do contrato"/>
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={3}>
                                <Form.Group>
                                    <DateRangePicker placeholder="Fim do contrato"/>
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                        <OwTable />                                          
                    </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </Container>
        </div>
    </SiteWrapper>
  );
}

export default Contracts;
