// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form,
} from "tabler-react";

import { Uploader, SelectPicker, DatePicker } from 'rsuite';

import SiteWrapper from "../../../SiteWrapper.react";

const contratos = [
    {"label" : "0001 - Elber Machado", "value" : "1"},
    {"label" : "0002 - Alexandre Alberto", "value" : "2"},
    {"label" : "0003 - Bela Alves", "value" : "3"},
    {"label" : "0004 - Carlos Villagran", "value" : "4"},
    {"label" : "0005 - Roberto Gomez", "value" : "5"},
    {"label" : "0006 - Florinda Meza", "value" : "6"},
    {"label" : "0007 - Maria Antonieta", "value" : "7"},
];

function CreateIncome() {
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Receita</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>                    
                            <Grid.Row className="align-items-center">
                                <Grid.Col sm={6}>
                                    <Form.Group label="Contrato">
                                        <SelectPicker placeholder="Selecione o contrato" data={contratos} block />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col sm={6}>
                                    <Form.Group label="Valor">
                                        <Form.Input type="text"  placeholder="Valor" />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row> 
                            <Form.Group>
                                <Grid.Row className="align-items-center">
                                        <Grid.Col md={6}>
                                            <Form.Group label="Emissão">
                                                <DatePicker block format="DD/MM/YYYY" />
                                            </Form.Group>
                                        </Grid.Col>
                                        <Grid.Col md={6}>
                                            <Form.Group label="Vencimento">
                                                <DatePicker block format="DD/MM/YYYY" />
                                            </Form.Group>
                                        </Grid.Col>
                                </Grid.Row>
                            </Form.Group> 
                            <Form.Group label="Notas">
                                <Form.Textarea rows={5} placeholder="Notas"/>
                            </Form.Group>
                            <Grid.Row className="align-items-center">
                                <Grid.Col sm={6}>
                                    <Form.Group>
                                        <Form.Input type="text"  placeholder="Documento" label="Documento"/>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col sm={6}>
                                    <Form.Group label="Arquivo">
                                        <Uploader action="//aldesenvolvimento.com.br/posts/" />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                        </Form>                           
                    </Card.Body>
                    <Card.Footer>
                            <Button.List className="mt-4" align="right">
                                <Button color="secondary" RootComponent="a" href="/receitas">Cancelar</Button>
                                <Button color="primary">Salvar</Button>
                            </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default CreateIncome;
