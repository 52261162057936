import React from 'react';
import { Table } from 'rsuite';
import { Button, IconButton, ButtonGroup, ButtonToolbar } from 'rsuite';
import { Icon, Tag } from 'rsuite';
import { Dropdown } from 'rsuite';

import OwActionButtons from './OwActionButtons';

const { Column, HeaderCell, Cell } = Table;

const fakeData = [
    {"id": 1, "fonte":"FASP", "data": "25/06/2020", "usuario" : "Joana"},
    {"id": 2, "fonte":"FASP", "data": "26/06/2020", "usuario" : "Joana"},
    {"id": 3, "fonte":"FASP", "data": "27/06/2020", "usuario" : "Joana"},
    {"id": 4, "fonte":"FASP", "data": "28/06/2020", "usuario" : "Joana"},
    {"id": 5, "fonte":"FASP", "data": "29/06/2020", "usuario" : "Joana"},
    {"id": 6, "fonte":"FASP", "data": "30/06/2020", "usuario" : "Joana"},
    {"id": 7, "fonte":"FASP", "data": "01/07/2020", "usuario" : "Joana"},
    {"id": 8, "fonte":"FASP", "data": "02/07/2020", "usuario" : "Joana"},
    {"id": 9, "fonte":"FASP", "data": "03/07/2020", "usuario" : "Joana"},
    {"id": 10, "fonte":"FASP", "data": "04/07/2020", "usuario" : "Joana"},
    {"id": 11, "fonte":"FASP", "data": "05/07/2020", "usuario" : "Joana"},
    {"id": 12, "fonte":"FASP", "data": "06/07/2020", "usuario" : "Joana"},
    {"id": 13, "fonte":"FASP", "data": "07/07/2020", "usuario" : "Joana"},
    {"id": 14, "fonte":"FASP", "data": "08/07/2020", "usuario" : "Joana"},
    {"id": 15, "fonte":"FASP", "data": "09/07/2020", "usuario" : "Joana"},
    {"id": 16, "fonte":"FASP", "data": "10/07/2020", "usuario" : "Joana"},

];

class OwTable extends React.Component {
    constructor(props) {
      super(props);
      //const data = fakeData.filter((v, i) => i < 100);
      //const data = fakeData;
      this.state = {
        data: props.data || fakeData,
        addColumn: false,
        displayLength: 10,
        loading: false,
        page: 1
      };
      this.handleSortColumn = this.handleSortColumn.bind(this);
      this.handleChangePage = this.handleChangePage.bind(this);
      this.handleChangeLength = this.handleChangeLength.bind(this);
    }
  
    getData() {
      const { sortColumn, sortType, displayLength, page } = this.state;

      var repo = fakeData;
  
      if (sortColumn && sortType) {
        repo = fakeData.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt();
          }
          if (typeof y === 'string') {
            y = y.charCodeAt();
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }

      return repo.filter((v, i) => {
          const start = displayLength * (page - 1);
          const end = start + displayLength;
          return i >= start && i < end;
      });

    }
  
    handleSortColumn(sortColumn, sortType) {
      this.setState({
        loading: true
      });
  
      setTimeout(() => {
        this.setState({
          sortColumn,
          sortType,
          loading: false
        });
      }, 500);
    }

    handleChangePage(dataKey) {
      this.setState({
        page: dataKey
      });
    }

    handleChangeLength(dataKey) {
      this.setState({
        page: 1,
        displayLength: dataKey
      });
    }
  
    render() {
      const data = this.getData();
      const { loading, displayLength, page, sortColumn, sortType  } = this.state;

      return (
        <div>
          <Table
            autoHeight
            height={420}
            data={this.getData()}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={this.handleSortColumn}
            loading={loading}
          >
            <Column width={100} align="center" fixed sortable resizable>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>

            <Column width={300} sortable resizable>
              <HeaderCell>Fonte</HeaderCell>
              <Cell dataKey="fonte" />
            </Column>
  
            <Column width={250} fixed sortable resizable>
              <HeaderCell>Data</HeaderCell>
              <Cell dataKey="data" />
            </Column>
  
            <Column width={300} sortable resizable>
              <HeaderCell>Usuário</HeaderCell>
              <Cell dataKey="usuario" />
            </Column>
          </Table>
          <Table.Pagination
            lengthMenu={[
              {
                value: 10,
                label: 10
              },
              {
                value: 25,
                label: 25
              },
              {
                value: 50,
                label: 50
              },
              {
                value: 100,
                label: 100
              }
            ]}
            activePage={page}
            displayLength={displayLength}
            total={fakeData.length}
            onChangePage={this.handleChangePage}
            onChangeLength={this.handleChangeLength}
          />
        </div>
      );
    }
}

export  default OwTable;