// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form
} from "tabler-react";

import {
    Table,
    Dropdown 
}
from "tabler-react";
//import Dropdown from "tabler-react";

import SiteWrapper from "../../SiteWrapper.react";
import OwTable from "../../components/OwTableCustomers";

function Customers() {
  return (
        <SiteWrapper>
        <div className="my-3 my-md-5">
        <Container>
            <Grid.Row>
                <Grid.Col md={12}>
                    <Card>
                    <Card.Header>
                        <Card.Title>Listagem de Clientes</Card.Title>
                        <Card.Options>
                            <Button color="success" icon="plus" size="sm" className="text-white" RootComponent="a" href="/cliente">Adicionar</Button>
                        </Card.Options>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row className="align-items-center">
                            <Grid.Col sm={12}>
                                <Form.Group >
                                    <Form.Input
                                        className="mb-3"
                                        icon="search"
                                        placeholder="Buscar cliente por nome, cpf, rg..."
                                        position="append"
                                    />
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                        <OwTable />
                    </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </Container>
        </div>
    </SiteWrapper>
  );
}

export default Customers;
