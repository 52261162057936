import React from 'react';
import { Modal, Button, Icon, IconButton } from 'rsuite';
import { Form, FormGroup, Message, ControlLabel, InputNumber} from 'rsuite';

class OwModal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        show: false,
        textButton: props.textButton,
        textTitle: props.textTitle,
        textBody: props.textBody,
      };
      this.close = this.close.bind(this);
      this.open = this.open.bind(this);
    }
    close() {
      this.setState({ show: false });
    }
    open() {
      this.setState({ show: true });
    }
    render() {
      return (
        <div>
          <IconButton color="green" icon={<Icon icon="check" />} size="sm" onClick={this.open}> Quitar</IconButton>
          <div className="modal-container">          
            <Modal show={this.state.show} onHide={this.close}>
              <Modal.Header>
                <Modal.Title>Quitação</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Message style={{ marginBottom: 15 + 'px'}}
                    description='Atenção! Ao quitar o contrato o sistema dará baixa em todos os lançamentos de recebimento e o contrato receberá o status de "Concluído".' 
                  /> 
                  <Form fluid>
                      <FormGroup>
                          <ControlLabel>Desconto</ControlLabel>
                          <InputNumber postfix="%" min={0} max={100} />
                      </FormGroup>
                  </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.close} appearance="subtle">
                  Cancelar
                </Button>
                <Button onClick={this.close} appearance="primary">
                  Aplicar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      );
    }
  }
  
  export default OwModal;