import React from 'react';
import { Table } from 'rsuite';
import { Button, IconButton, ButtonGroup, ButtonToolbar } from 'rsuite';
import { Tag } from 'rsuite';

import OwActionButtons from './OwActionButtons';

const { Column, HeaderCell, Cell } = Table;

const fakeData = [
    {"id": 1, "contrato":"0001","documento" : "FAT0001/1", "cliente" : "Elber Machado", "emissao": "25/04/2020", "vencimento": "25/04/2020", "valor" : "R$ 5.500,00", "status" : "Pago"},
    {"id": 2, "contrato":"0001","documento" : "FAT0001/2", "cliente" : "Elber Machado", "emissao": "25/04/2020", "vencimento": "25/05/2020", "valor" : "R$ 5.500,00", "status" : "Parcial"},
    {"id": 3, "contrato":"0002","documento" : "FAT0002/1", "cliente" : "Alexandre Alberto", "emissao": "20/06/2020", "vencimento": "20/07/2020", "valor" : "R$ 23.000,00", "status" : "Pendente"},
    {"id": 4, "contrato":"0003","documento" : "FAT0003/1", "cliente" : "Bela Alves", "emissao": "30/06/2020", "vencimento": "15/07/2020", "valor" : "R$ 12.000,00", "status" : "Vencido"},
    {"id": 5, "contrato":"0004","documento" : "FAT0004/1", "cliente" : "Carlos Villagran", "emissao": "30/06/2020", "vencimento": "15/07/2020", "valor" : "R$ 12.000,00", "status" : "Vencido"},
    {"id": 6, "contrato":"0004","documento" : "FAT0004/2", "cliente" : "Carlos Villagran", "emissao": "30/06/2020", "vencimento": "15/08/2020", "valor" : "R$ 12.000,00", "status" : "Pendente"},
    {"id": 7, "contrato":"0005","documento" : "FAT0004/3", "cliente" : "Carlos Villagran", "emissao": "30/06/2020", "vencimento": "15/09/2020", "valor" : "R$ 12.000,00", "status" : "Pendente"},
    {"id": 8, "contrato":"0006","documento" : "FAT0005/1", "cliente" : "Roberto Gomez", "emissao": "30/06/2020", "vencimento": "15/07/2020", "valor" : "R$ 8.000,00", "status" : "Parcial"},
    {"id": 9, "contrato":"0007","documento" : "FAT0006/1", "cliente" : "Florinda Meza", "emissao": "30/06/2020", "vencimento": "15/07/2020", "valor" : "R$ 23.000,00", "status" : "Vencido"},
    {"id": 10, "contrato":"0007","documento" : "FAT0006/2", "cliente" : "Maria Antonieta", "emissao": "30/06/2020", "vencimento": "15/07/2020", "valor" : "R$ 32.000,00", "status" : "Vencido"},
    {"id": 11, "contrato":"0008","documento" : "FAT0007/1", "cliente" : "Edgar Vivar", "emissao": "30/06/2020", "vencimento": "15/07/2020", "valor" : "R$ 15.000,00", "status" : "Pendente"},
    {"id": 12, "contrato":"0008","documento" : "FAT0008/1", "cliente" : "Edgar Vivar", "emissao": "30/06/2020", "vencimento": "15/07/2020", "valor" : "R$ 18.000,00", "status" : "Pendente"},

];

class OwTable extends React.Component {
    constructor(props) {
      super(props);
      //const data = fakeData.filter((v, i) => i < 100);
      //const data = fakeData;
      this.state = {
        data: props.data || fakeData,
        addColumn: false,
        displayLength: 10,
        loading: false,
        page: 1
      };
      this.handleSortColumn = this.handleSortColumn.bind(this);
      this.handleChangePage = this.handleChangePage.bind(this);
      this.handleChangeLength = this.handleChangeLength.bind(this);
    }
  
    getData() {
      const { sortColumn, sortType, displayLength, page } = this.state;

      var repo = fakeData;
  
      if (sortColumn && sortType) {
        repo = fakeData.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt();
          }
          if (typeof y === 'string') {
            y = y.charCodeAt();
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }

      return repo.filter((v, i) => {
          const start = displayLength * (page - 1);
          const end = start + displayLength;
          return i >= start && i < end;
      });

    }
  
    handleSortColumn(sortColumn, sortType) {
      this.setState({
        loading: true
      });
  
      setTimeout(() => {
        this.setState({
          sortColumn,
          sortType,
          loading: false
        });
      }, 500);
    }

    handleChangePage(dataKey) {
      this.setState({
        page: dataKey
      });
    }

    handleChangeLength(dataKey) {
      this.setState({
        page: 1,
        displayLength: dataKey
      });
    }
  
    render() {
      const data = this.getData();
      const { loading, displayLength, page, sortColumn, sortType  } = this.state;

      return (
        <div>
          <Table
            autoHeight
            height={420}
            data={this.getData()}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={this.handleSortColumn}
            loading={loading}
          >
            <Column width={50} align="center" fixed sortable resizable>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
  
            <Column width={85} fixed sortable resizable>
              <HeaderCell>Contrato</HeaderCell>
              <Cell dataKey="contrato" />
            </Column>
  
            <Column width={100} sortable resizable>
              <HeaderCell>Documento</HeaderCell>
              <Cell dataKey="documento" />
            </Column>
  
            <Column width={150} sortable resizable>
              <HeaderCell>Cliente</HeaderCell>
              <Cell dataKey="cliente" />
            </Column>
  
            <Column width={150} resizable sortable>
              <HeaderCell>Emissão</HeaderCell>
              <Cell dataKey="emissao" />
            </Column>

            <Column width={150} resizable sortable>
              <HeaderCell>Vencimento</HeaderCell>
              <Cell dataKey="vencimento" />
            </Column>

            <Column width={150} resizable sortable> 
              <HeaderCell>Valor</HeaderCell>
              <Cell dataKey="valor" />
            </Column>

            <Column width={100} resizable sortable>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="status">
                {rowData => {
                  let color = "red";

                  if(rowData.status === 'Pago')
                    color = "green";
                  else if(rowData.status === 'Parcial')
                    color="orange";
                  else if(rowData.status === 'Pendente')
                    color = 'yellow';

                  return (
                    <Tag color={color}> {rowData.status} </Tag>
                  );
                }}
              </Cell>
            </Column>

            <Column align="center" fixed>
              <HeaderCell>Ações</HeaderCell>
              <Cell>
                {rowData => {
                  return (
                    <div style={{marginTop: -9 + 'px'}}>
                      <OwActionButtons rowId={rowData.id} urlPrefix="receita"/>
                    </div>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <Table.Pagination
            lengthMenu={[
              {
                value: 10,
                label: 10
              },
              {
                value: 25,
                label: 25
              },
              {
                value: 50,
                label: 50
              },
              {
                value: 100,
                label: 100
              }
            ]}
            activePage={page}
            displayLength={displayLength}
            total={fakeData.length}
            onChangePage={this.handleChangePage}
            onChangeLength={this.handleChangeLength}
          />
        </div>
      );
    }
}

export  default OwTable;