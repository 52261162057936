// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form,
  Tab,
  Tabs,
} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";

import { Uploader } from 'rsuite';

function CreateCustomer() {
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Cliente</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>                    
                            <Tabs initialTab="Geral">
                                <Tab title="Geral">
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={12}>
                                                <Form.Input type="text" placeholder='Nome Completo' label="Nome Completo"/>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                    <Form.Group label="CPF">
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={4}>
                                                    <Form.Input type="text"  placeholder="CPF"/>                                                    
                                            </Grid.Col>
                                            <Grid.Col sm={4}>
                                                <Form.FileInput label="Anexar frente do CPF" />
                                            </Grid.Col>
                                            <Grid.Col sm={4}>
                                                <Form.FileInput label="Anexar costas do CPF" />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.Group>
                                    <Form.Group label="RG">
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={4}>
                                                    <Form.Input type="text"  placeholder="RG"/>                                                    
                                            </Grid.Col>
                                            <Grid.Col sm={4}>
                                                <Form.FileInput label="Anexar frente do RG" />
                                            </Grid.Col>
                                            <Grid.Col sm={4}>
                                                <Form.FileInput label="Anexar costas do RG" />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={12}>
                                                <Form.Textarea rows={5} label="Observações" placeholder="Observações"/>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.Group>
                                </Tab>
                                <Tab title="Contatos">
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={6}>
                                                <Form.Input type="tel" placeholder='Telefone' label="Telefone"/>
                                            </Grid.Col>
                                            <Grid.Col sm={6}>
                                                <Form.Input type="tel" placeholder='Celular' label="Celular"/>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={12}>
                                                <Form.Input type="email" placeholder='E-mail' label="E-mail"/>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                </Tab>
                                <Tab title="Endereço">
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={4}>
                                                <Form.Input type="text" placeholder='CEP' label="CEP" />
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={6}>
                                                <Form.Input type="text" placeholder='Rua' label="Rua"/>
                                            </Grid.Col>
                                            <Grid.Col sm={2}>
                                                <Form.Input type="text" placeholder='Número' label="Número"/>
                                            </Grid.Col>
                                            <Grid.Col sm={4}>
                                                <Form.Input type="text" placeholder='Complemento'  label="Complemento"/>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={5}>
                                                <Form.Input type="text" placeholder='Bairro' label="Bairro"/>
                                            </Grid.Col>
                                            <Grid.Col sm={5}>
                                                <Form.Input type="text" placeholder='Cidade' label="Cidade"/>
                                            </Grid.Col>
                                            <Grid.Col sm={2}>
                                                <Form.Select type="text" placeholder='Estado' label="Estado"> 
                                                    <option value="">Estado</option>
                                                    <option value="AC">Acre</option>
                                                    <option value="AL">Alagoas</option>
                                                    <option value="AP">Amapá</option>
                                                    <option value="AM">Amazonas</option>
                                                    <option value="BA">Bahia</option>
                                                    <option value="CE">Ceará</option>
                                                    <option value="DF">Distrito Federal</option>
                                                    <option value="ES">Espírito Santo</option>
                                                    <option value="GO">Goiás</option>
                                                    <option value="MA">Maranhão</option>
                                                    <option value="MT">Mato Grosso</option>
                                                    <option value="MS">Mato Grosso do Sul</option>
                                                    <option value="MG">Minas Gerais</option>
                                                    <option value="PA">Pará</option>
                                                    <option value="PB">Paraíba</option>
                                                    <option value="PR">Paraná</option>
                                                    <option value="PE">Pernambuco</option>
                                                    <option value="PI">Piauí</option>
                                                    <option value="RJ">Rio de Janeiro</option>
                                                    <option value="RN">Rio Grande do Norte</option>
                                                    <option value="RS">Rio Grande do Sul</option>
                                                    <option value="RO">Rondônia</option>
                                                    <option value="RR">Roraima</option>
                                                    <option value="SC">Santa Catarina</option>
                                                    <option value="SP">São Paulo</option>
                                                    <option value="SE">Sergipe</option>
                                                    <option value="TO">Tocantins</option>
                                                    <option value="EX">Estrangeiro</option>
                                                </Form.Select>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                </Tab>
                                <Tab title="Dados Bancários">
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={4}>
                                                <Form.Select label="Banco/Instituição"> 
                                                    <option value="">Selecione o banco</option>
                                                    <option value="001">Banco do Brasil</option>
                                                    <option value="003">Banco da Amazônia</option>
                                                    <option value="004">Banco do Nordeste</option>
                                                    <option value="021">Banestes</option>
                                                    <option value="025">Banco Alfa</option>
                                                    <option value="027">Besc</option>
                                                    <option value="029">Banerj</option>
                                                    <option value="031">Banco Beg</option>
                                                    <option value="033">Banco Santander Banespa</option>
                                                    <option value="036">Banco Bem</option>
                                                    <option value="037">Banpará</option>
                                                    <option value="038">Banestado</option>
                                                    <option value="039">BEP</option>
                                                    <option value="040">Banco Cargill</option>
                                                    <option value="041">Banrisul</option>
                                                    <option value="044">BVA</option>
                                                    <option value="045">Banco Opportunity</option>
                                                    <option value="047">Banese</option>
                                                    <option value="062">Hipercard</option>
                                                    <option value="063">Ibibank</option>
                                                    <option value="065">Lemon Bank</option>
                                                    <option value="066">Banco Morgan Stanley Dean Witter</option>
                                                    <option value="069">BPN Brasil</option>
                                                    <option value="070">Banco de Brasília – BRB</option>
                                                    <option value="072">Banco Rural</option>
                                                    <option value="073">Banco Popular</option>
                                                    <option value="074">Banco J. Safra</option>
                                                    <option value="075">Banco CR2</option>
                                                    <option value="076">Banco KDB</option>
                                                    <option value="096">Banco BMF</option>
                                                    <option value="104">Caixa Econômica Federal</option>
                                                    <option value="107">Banco BBM</option>
                                                    <option value="116">Banco Único</option>
                                                    <option value="151">Nossa Caixa</option>
                                                    <option value="175">Banco Finasa</option>
                                                    <option value="184">Banco Itaú BBA</option>
                                                    <option value="204">American Express Bank</option>
                                                    <option value="208">Banco Pactual</option>
                                                    <option value="212">Banco Matone</option>
                                                    <option value="213">Banco Arbi</option>
                                                    <option value="214">Banco Dibens</option>
                                                    <option value="217">Banco Joh Deere</option>
                                                    <option value="218">Banco Bonsucesso</option>
                                                    <option value="222">Banco Calyon Brasil</option>
                                                    <option value="224">Banco Fibra</option>
                                                    <option value="225">Banco Brascan</option>
                                                    <option value="229">Banco Cruzeiro</option>
                                                    <option value="230">Unicard</option>
                                                    <option value="233">Banco GE Capital</option>
                                                    <option value="237">Bradesco</option>
                                                    <option value="241">Banco Clássico</option>
                                                    <option value="243">Banco Stock Máxima</option>
                                                    <option value="246">Banco ABC Brasil</option>
                                                    <option value="248">Banco Boavista Interatlântico</option>
                                                    <option value="249">Investcred Unibanco</option>
                                                    <option value="250">Banco Schahin</option>
                                                    <option value="252">Fininvest</option>
                                                    <option value="254">Paraná Banco</option>
                                                    <option value="263">Banco Cacique</option>
                                                    <option value="265">Banco Fator</option>
                                                    <option value="266">Banco Cédula</option>
                                                    <option value="300">Banco de la Nación Argentina</option>
                                                    <option value="318">Banco BMG</option>
                                                    <option value="320">Banco Industrial e Comercial</option>
                                                    <option value="356">ABN Amro Real</option>
                                                    <option value="341">Itau</option>
                                                    <option value="347">Sudameris</option>
                                                    <option value="351">Banco Santander</option>
                                                    <option value="353">Banco Santander Brasil</option>
                                                    <option value="366">Banco Societe Generale Brasil</option>
                                                    <option value="370">Banco WestLB</option>
                                                    <option value="376">JP Morgan</option>
                                                    <option value="389">Banco Mercantil do Brasil</option>
                                                    <option value="394">Banco Mercantil de Crédito</option>
                                                    <option value="399">HSBC</option>
                                                    <option value="409">Unibanco</option>
                                                    <option value="412">Banco Capital</option>
                                                    <option value="422">Banco Safra</option>
                                                    <option value="453">Banco Rural</option>
                                                    <option value="456">Banco Tokyo Mitsubishi UFJ</option>
                                                    <option value="464">Banco Sumitomo Mitsui Brasileiro</option>
                                                    <option value="477">Citibank</option>
                                                    <option value="479">Itaubank (antigo Bank Boston)</option>
                                                    <option value="487">Deutsche Bank</option>
                                                    <option value="488">Banco Morgan Guaranty</option>
                                                    <option value="492">Banco NMB Postbank</option>
                                                    <option value="494">Banco la República Oriental del Uruguay</option>
                                                    <option value="495">Banco La Provincia de Buenos Aires</option>
                                                    <option value="505">Banco Credit Suisse</option>
                                                    <option value="600">Banco Luso Brasileiro</option>
                                                    <option value="604">Banco Industrial</option>
                                                    <option value="610">Banco VR</option>
                                                    <option value="611">Banco Paulista</option>
                                                    <option value="612">Banco Guanabara</option>
                                                    <option value="613">Banco Pecunia</option>
                                                    <option value="623">Banco Panamericano</option>
                                                    <option value="626">Banco Ficsa</option>
                                                    <option value="630">Banco Intercap</option>
                                                    <option value="633">Banco Rendimento</option>
                                                    <option value="634">Banco Triângulo</option>
                                                    <option value="637">Banco Sofisa</option>
                                                    <option value="638">Banco Prosper</option>
                                                    <option value="643">Banco Pine</option>
                                                    <option value="652">Itaú Holding Financeira</option>
                                                    <option value="653">Banco Indusval</option>
                                                    <option value="654">Banco A.J. Renner</option>
                                                    <option value="655">Banco Votorantim</option>
                                                    <option value="707">Banco Daycoval</option>
                                                    <option value="719">Banif</option>
                                                    <option value="721">Banco Credibel</option>
                                                    <option value="734">Banco Gerdau</option>
                                                    <option value="735">Banco Pottencial</option>
                                                    <option value="738">Banco Morada</option>
                                                    <option value="739">Banco Galvão de Negócios</option>
                                                    <option value="740">Banco Barclays</option>
                                                    <option value="741">BRP</option>
                                                    <option value="743">Banco Semear</option>
                                                    <option value="745">Banco Citibank</option>
                                                    <option value="746">Banco Modal</option>
                                                    <option value="747">Banco Rabobank International</option>
                                                    <option value="748">Banco Cooperativo Sicredi</option>
                                                    <option value="749">Banco Simples</option>
                                                    <option value="751">Dresdner Bank</option>
                                                    <option value="752">BNP Paribas</option>
                                                    <option value="753">Banco Comercial Uruguai</option>
                                                    <option value="755">Banco Merrill Lynch</option>
                                                    <option value="756">Banco Cooperativo do Brasil</option>
                                                    <option value="757">KEB</option>
                                                </Form.Select>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                    <Form.Group>
                                        <Grid.Row className="align-items-center">
                                            <Grid.Col sm={4}>
                                                <Form.Input type="text" placeholder='Agência' label="Agência"/>
                                            </Grid.Col>
                                            <Grid.Col sm={4}>
                                                <Form.Input type="text" placeholder='Conta' label="Conta"/>
                                            </Grid.Col>
                                            <Grid.Col sm={4}>
                                                <Form.Group label="Tipo de Conta">
                                                    <Form.SwitchStack>
                                                        <Form.Switch
                                                            checked
                                                            type="radio"
                                                            name="toggle"
                                                            value="CC"
                                                            label="Conta Corrente"
                                                        />
                                                        <Form.Switch                                                            
                                                            type="radio"
                                                            name="toggle"
                                                            value="CP"
                                                            label="Conta Poupança"
                                                        />
                                                    </Form.SwitchStack>
                                                </Form.Group>
                                            </Grid.Col>
                                        </Grid.Row> 
                                    </Form.Group>
                                </Tab>                                
                                <Tab title="Arquivos">
                                    <Grid.Row className="align-items-center">
                                        <Grid.Col sm={12}>
                                            <Form.Group >
                                                <Uploader action="//jsonplaceholder.typicode.com/posts/" draggable>
                                                    <div style={{lineHeight: 200 + 'px'}}>Clique ou arraste os arquivos aqui</div>
                                                </Uploader>
                                            </Form.Group>
                                        </Grid.Col>
                                    </Grid.Row> 
                                </Tab>
                            </Tabs>

                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <Button.List className="mt-4" align="right">
                            <Button color="secondary" RootComponent="a" href="/clientes">Cancelar</Button>
                            <Button color="primary">Salvar</Button>
                        </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default CreateCustomer;
