import React from 'react';
import { Dropdown, IconButton, Icon, Popover, Whisper } from 'rsuite';

const MenuPopover = ({ onSelect, rowId, urlPrefix, ...rest }) => (
    <Popover {...rest} full>
      <Dropdown.Menu onSelect={onSelect}>
        <Dropdown.Item eventKey={1} href={'/' + urlPrefix + '/' + rowId}>Visualizar</Dropdown.Item>
        <Dropdown.Item eventKey={2}>Duplicar</Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item eventKey={3}>Remover</Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

const triggerRef = React.createRef();
    
function handleSelectMenu(eventKey, event) {
    console.log(triggerRef);
    triggerRef.current.hide();
}

class OwActionButtons extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            rowId: props.rowId,
            urlPrefix: props.urlPrefix
        }
    }

    render() {
      return (
        <Whisper
            placement="bottomStart"
            trigger={['click']}
            triggerRef={triggerRef}
            speaker={<MenuPopover onSelect={handleSelectMenu} rowId={this.state.rowId} urlPrefix={this.state.urlPrefix}/>}
        >
            <IconButton icon={<Icon icon="more" />} />
        </Whisper>
      )
    }
}

export  default OwActionButtons;