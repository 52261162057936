// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form,
} from "tabler-react";

import { Uploader, SelectPicker, DatePicker } from 'rsuite';

import SiteWrapper from "../../SiteWrapper.react";

const usuarios = [
    {"label" : "Anderson Brito", "value" : "1"},
    {"label" : "Joana Alves", "value" : "2"},
    {"label" : "Márcio Farias", "value" : "3"},
    {"label" : "Luana Vasconcelos", "value" : "4"},
];

function CreateCheck() {
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Cheque</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>                    
                            <Grid.Row className="align-items-center">
                                <Grid.Col sm={12}>
                                    <Form.Group label="Usuário">
                                        <SelectPicker placeholder="Selecione o usuário dono da assinatura" data={usuarios} block />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col sm={12}>
                                    <Form.Group label="Assinatura">
                                        <Uploader action="//aldesenvolvimento.com.br/posts/" />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row> 
                        </Form>                           
                    </Card.Body>
                    <Card.Footer>
                            <Button.List className="mt-4" align="right">
                                <Button color="secondary" RootComponent="a" href="/assinaturas">Cancelar</Button>
                                <Button color="primary">Salvar</Button>
                            </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default CreateCheck;