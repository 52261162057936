import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  /*ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  Error400,
  Error401,
  Error403,*/
  Error404,
  /*Error500,
  Error503,
  Empty,
  Email,
  ProfilePage,*/
} from "./pages";

/*import HomePage from "./HomePage.react";
import FormElementsPage from "./FormElementsPage.react";
import PricingCardsPage from "./interface/PricingCardsPage.react";
import CardsDesignPage from "./interface/CardsDesignPage.react";
import StoreCardsPage from "./components/StoreCardsPage.react.js";
import IconPage from "./components/IconPage.react.js";
import ChartsPage from "./interface/ChartsPage.react";
import GalleryPage from "./GalleryPage.react";
import MapCardsPage from "./components/MapCardsPage.react";
import BlogPage from "./components/BlogPage.react";*/

import Customers from "./pages/customers/Customers";
import CreateCustomer from "./pages/customers/CreateCustomer";
import EditCustomer from "./pages/customers/EditCustomer";
import Contracts from "./pages/contracts/Contracts";
import CreateContract from "./pages/contracts/CreateContract";
import EditContract from "./pages/contracts/EditContract";
import Incomes from "./pages/finance/incomes/Incomes";
import CreateIncome from "./pages/finance/incomes/CreateIncome";
import EditIncome from "./pages/finance/incomes/EditIncome";
import Payment from "./pages/finance/Payment";
import EditPayment from "./pages/finance/EditPayment";
import Cheks from "./pages/finance/checks/Main";
import CreateCheck from "./pages/finance/checks/Create";
import EditCheck from "./pages/finance/checks/Edit";
import PaymentsFromFile from "./pages/finance/paymentsFromFile/Main";
import CreatePaymentFromFile from "./pages/finance/paymentsFromFile/Create";
import DivergencyIncomes from "./pages/finance/reports/divergencyIncomes";
import Signatures from "./pages/signatures/Main";
import CreateSignature from "./pages/signatures/Create";
import EditSignature from "./pages/signatures/Edit";


import 'rsuite/dist/styles/rsuite-default.css';
import "tabler-react/dist/Tabler.css";


type Props = {||};

function App(props: Props): React.Node {
  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path="/" component={Customers} />
          <Route exact path="/clientes" component={Customers} />
          <Route exact path="/cliente/" component={CreateCustomer} />
          <Route exact path="/cliente/:id" component={EditCustomer} />
          <Route exact path="/contratos" component={Contracts} />
          <Route exact path="/contrato" component={CreateContract} />
          <Route exact path="/contrato/:id" component={EditContract} />
          <Route exact path="/receitas" component={Incomes} />
          <Route exact path="/receita" component={CreateIncome} />
          <Route exact path="/pagamento" component={Payment} />
          <Route exact path="/pagamento/:id" component={EditPayment} />
          <Route exact path="/receita/:id" component={EditIncome} />
          <Route exact path="/cheques" component={Cheks} />
          <Route exact path="/cheque" component={CreateCheck} />
          <Route exact path="/cheque/:id" component={EditCheck} />
          <Route exact path="/baixas-arquivo" component={PaymentsFromFile} />
          <Route exact path="/baixa-arquivo" component={CreatePaymentFromFile} />
          <Route exact path="/divergencias" component={DivergencyIncomes} />
          <Route exact path="/assinaturas" component={Signatures} />
          <Route exact path="/assinatura" component={CreateSignature} />
          <Route exact path="/assinatura/:id" component={EditSignature} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </React.StrictMode>
  );
}

export default App;
