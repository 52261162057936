// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form
} from "tabler-react";

import { TagPicker } from 'rsuite';

import DateRangePicker from '../../../components/DateRangePicker';
import OwTable from '../../../components/OwTable';


import SiteWrapper from "../../../SiteWrapper.react";

function Incomes() {
  return (
        <SiteWrapper>
        <div className="my-3 my-md-5">
        <Container>
            <Grid.Row>
                <Grid.Col md={12}>
                    <Card>
                    <Card.Header>
                        <Card.Title>Listagem de Receitas</Card.Title>
                        <Card.Options>
                            <Button color="success" icon="chevrons-down" size="sm" className="text-white" RootComponent="a" href="/despesa/baixa-arquivo">Baixa via arquivo</Button>
                            <Button color="success" icon="plus" size="sm" className="text-white" RootComponent="a" href="/receita">Adicionar</Button>
                        </Card.Options>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row className="align-items-center">
                            <Grid.Col md={3}>
                                <Form.Group >
                                    <Form.Input
                                        size="sm"
                                        className="mb-3"
                                        icon="search"
                                        placeholder="Buscar por contrato, cliente, documento..."
                                        position="append"
                                    />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={3}>
                                <Form.Group>
                                    <DateRangePicker placeholder="Emissão"/>
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={3}>
                                <Form.Group>
                                    <DateRangePicker placeholder="Vencimento"/>
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={3}>
                                <Form.Group>
                                <TagPicker 
                                    placeholder="Status"
                                    data={[
                                        {'label':'Pendentes', 'value':'pendente','role':'master'},
                                        {'label':'Parciais', 'value':'parcial','role':'master'},
                                        {'label':'Vencidos', 'value':'vencidos','role':'master'},
                                        {'label':'Pagos', 'value':'pagos','role':'master'},
                                    ]} 
                                    style={{ width: 300 }}  />
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                        <OwTable />                      
                    </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        </Container>
        </div>
    </SiteWrapper>
  );
}

export default Incomes;
