// @flow

import React from "react";
import {
  Grid,
  Button,
  Card,
  Container,
  Form,
} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";

function CreateContract() {
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Grid.Row>
            <Grid.Col md={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Contrato</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>                    
                        <Form.Group>
                            <Grid.Row className="align-items-center">
                                    <Grid.Col md={12}>
                                        <Form.Select type="text" placeholder='Cliente' label="Cliente"> 
                                            <option value="">Selecione o cliente</option>
                                            <option value="1">Elias Silva</option>
                                            <option value="2">Alexander Lima</option>
                                            <option value="3">Edgar Vivar</option>
                                            <option value="4">Maria Antonieta</option>
                                            <option value="5">Florinda Meza</option>
                                            <option value="6">Ramon Valdez</option>
                                            <option value="7">Roberto Gomes</option>
                                        </Form.Select>
                                    </Grid.Col>
                            </Grid.Row> 
                        </Form.Group>
                        <Form.Group>
                            <Grid.Row className="align-items-center">
                                <Grid.Col md={3}>
                                    <Form.Input type="text"  placeholder="0,00" label="Valor do contrato"/>
                                </Grid.Col>
                                <Grid.Col md={3}>
                                    <Form.Input type="text"  placeholder="0,00" label="Valor do cliente"/>
                                </Grid.Col>
                                <Grid.Col md={3}>
                                    <Form.Input type="text"  placeholder="0,00" label="Nosso valor"/>
                                </Grid.Col>
                                <Grid.Col md={3}>
                                <Form.Group label="Nº Parcelas">
                                    <Form.Ratio
                                        defaultValue={12}
                                        max={48}
                                        min={1}
                                        step={1}
                                    />
                                </Form.Group>              
                            </Grid.Col>
                            </Grid.Row>
                        </Form.Group>      
                        <Form.Group>
                            <Grid.Row className="align-items-center">
                                    <Grid.Col md={6}>
                                        <Form.Group label="Início do contrato">
                                            <Form.DatePicker
                                                defaultDate={new Date("2020-06-29T15:58:41.842Z")}
                                                format="dd/mm/yyyy"
                                                maxYear={2020}
                                                minYear={1897}
                                                monthLabels={[
                                                'Janeiro',
                                                'Fevereiro',
                                                'Março',
                                                'Abril',
                                                'Maio',
                                                'Junho',
                                                'Julho',
                                                'Agosto',
                                                'Setembro',
                                                'Outubro',
                                                'Novembro',
                                                'Dezembro'
                                                ]}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                    <Form.Group label="Fim do contrato">
                                            <Form.DatePicker
                                                defaultDate={new Date("2021-06-29T15:58:41.842Z")}
                                                format="dd/mm/yyyy"
                                                maxYear={2100}
                                                minYear={2000}
                                                monthLabels={[
                                                'Janeiro',
                                                'Fevereiro',
                                                'Março',
                                                'Abril',
                                                'Maio',
                                                'Junho',
                                                'Julho',
                                                'Agosto',
                                                'Setembro',
                                                'Outubro',
                                                'Novembro',
                                                'Dezembro'
                                                ]}
                                            />
                                        </Form.Group>
                                    </Grid.Col>
                            </Grid.Row>
                        </Form.Group>  
                        <Grid.Row className="align-items-center">
                            <Grid.Col md={4}>
                                <Form.Group label="Anexar contrato">
                                    <Form.FileInput label="Selecione o arquivo" />
                                </Form.Group>              
                            </Grid.Col>
                            <Grid.Col md={4}>
                                <Form.Group label="Anexar Pós-Venda">
                                    <Form.FileInput label="Selecione o arquivo" />
                                </Form.Group>              
                            </Grid.Col>
                            <Grid.Col md={4}>
                                <Form.Group label="Anexar contracheque">
                                    <Form.FileInput label="Selecione o arquivo" />
                                </Form.Group>              
                            </Grid.Col>
                        </Grid.Row>
                        <Form.Group label="Observações">
                            <Form.Textarea rows={5} placeholder="Observações"/>
                        </Form.Group>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <Button.List className="mt-4" align="right">
                            <Button color="secondary" RootComponent="a" href="/contratos">Cancelar</Button>
                            <Button color="primary">Salvar</Button>
                        </Button.List>
                    </Card.Footer>
                </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default CreateContract;